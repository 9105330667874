<template>
    <div class="pdf-demo" id="demo" ref="demo">
    </div>
</template>

<script>
    import Pdfh5 from "pdfh5";
    import "pdfh5/css/pdfh5.css";
    
    var tid, tsid, timer, lid;
    import { getTaskDetail, finishDocLessonProcess } from '@/api/task.js';
    
    // import { getUser } from '../api/common.js';
    // import { setWaterMark, removeWatermark } from "../utils/watermark";
    import { toast, checkLogin } from '@/utils/common.js';
    export default {
        data() {
            return{
                pdfh5: null,
                info: null,
                tsid: '',
                tid: '',
                cur: '',
                list: [],
            }
        },
        activated() {
            tid = this.tid = this.$route.query.id; // 任务id2
            tsid = this.tsid = this.$route.query.tsid;// 任务结果id
            this.cur = +this.$route.query.cur;// 当前课程索引
            lid = this.$route.query.lid; //当前课程 首页传过来的不准确
            checkLogin().then(()=> {
               this.getDetail(tid, tsid);
            })
            
            this.hideTab();
        },
        deactivated() {
            clearInterval(timer);
            // removeWatermark();
        },
        methods:{
            getCur(){
                for (var i = 0; i < this.list.length; i++) {
                    var item = this.list[i];
                    if(item['lid'] == lid){
                        return i;
                    }
                }
            },
            getDetail(tid, tsid){
                getTaskDetail(tid, tsid).then((ret)=>{
                    var data = ret.data;
                    this.list = data.lessions;
                    this.cur = this.getCur();
                    this.info = data.lessions[this.cur];
                    this.initPdf(this.info['file_url']); 
                    // this.getUser();
                    // 第一次看
                    // if(this.info.speed_num == 1){
                    //     return;
                    // }
                    // timer = setTimeout(()=>{
                    //     // toast('观看完毕');
                    //     // finishViewDocProcess
                    //     finishDocLessonProcess(this.info.tluid, tid).then((ret)=>{
                    //         // 并不需要标记
                    //         console.log('已完成该课程', ret);
                    //     }).catch((err)=>{
                    //         toast(err.msg|| '更新总进度失败！', 'error')
                    //     })
                    // },1000*60*1)
                });
            },
            hideTab(){
                //console.log(document.querySelector('#vantab'));
                document.querySelector('#vantab').classList.add('tab-hide');
            },
            initPdf(pdfurl){
                if(this.pdfh5){
                    this.pdfh5.destroy();
                    this.pdfh5 = null;
                }
                this.pdfh5 = new Pdfh5(this.$refs.demo, {
                   pdfurl: pdfurl,
                }).on('complete', (status, msg)=>{
                    if(status == 'error'){
                       return  toast(msg);
                    }
                    if(this.info.speed_num == 1){
                        return;
                    }
                    if(timer){
                        clearTimeout(timer);
                    }
                    timer = setTimeout(()=>{
                        finishDocLessonProcess(this.info.tluid, tid).then((ret)=>{
                            // 并不需要标记
                            console.log('已完成该课程', ret);
                        }).catch((err)=>{
                            toast(err.msg|| '更新总进度失败！', 'error')
                        })
                    },1000*60*1)
                })
            },
        }
    }
</script>

<style scoped>
    .pdf-demo{
        min-height: 100vh;
    }
    /* @import "pdfh5/css/pdfh5.css"; */
</style>